import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderMedia from '@interness/theme-default/src/components/HeaderMedia';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <HeaderImage mdxType="HeaderImage">
      <HeaderMedia id='tauch' mdxType="HeaderMedia" />
    </HeaderImage>
    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <h1>{`Tauch - TU`}</h1>
      <p>{`Die Sportmedizin beschäftigt sich mit dem Einfluss von Bewegung, Training und von Sport auf die Gesundheit und mit der Vorbeugung, Diagnostik und Behandlung von Sportverletzungen und Sportschäden.`}</p>
      <p>{`Einfach rauf aufs Rad oder rein in die Laufschuhe? Für Untrainierte kann das Verletzungs- oder Krankheitsrisiken bergen. Lassen Sie sich besser untersuchen, bevor Sie mit dem Training beginnen.`}</p>
      <p>{`Die sportmedizinische Basisuntersuchung beinhaltet in der Regel:`}</p>
      <ul>
        <li parentName="ul">{`Anamnese`}</li>
        <li parentName="ul">{`Erhebung des Ganzkörperstatus`}</li>
        <li parentName="ul">{`Ruhe-EKG`}</li>
        <li parentName="ul">{`abschließende Besprechung`}</li>
        <li parentName="ul">{`Sofern zusätzliche Untersuchungen medizinisch erforderlich sind - zum Beispiel aufgrund besonderer Risikofaktoren -, kann eine erweiterte Untersuchung wahrgenommen werden.`}</li>
      </ul>
      <p>{`Hier folgen zusätzlich:`}</p>
      <ul>
        <li parentName="ul">{`Belastungs-EKG`}</li>
        <li parentName="ul">{`Lungenfunktionsprüfung.`}</li>
      </ul>
      <p>{`Sport-Tauglichkeitsuntersuchungen sind keine Leistung der gesetzlichen Krankenversicherung. Manche Krankenkassen erstatten einen Teil der anfallenden Kosten. Sprechen Sie mit Ihrer Krankenkasse.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
  <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      